import { render, staticRenderFns } from "./ProReportBooleanTdField.vue?vue&type=template&id=40a1da5c&scoped=true&"
import script from "./ProReportBooleanTdField.vue?vue&type=script&lang=js&"
export * from "./ProReportBooleanTdField.vue?vue&type=script&lang=js&"
import style0 from "./ProReportBooleanTdField.vue?vue&type=style&index=0&id=40a1da5c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a1da5c",
  null
  
)

export default component.exports
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QChip});
