<template>
  <div>
    <q-chip square :text-color="getColor">
      <span style="font-weight: 600">
        {{ getString() }}
      </span>
    </q-chip>
  </div>
</template>

<script>
export default {
  name: "ProReportBooleanTdField",
  props: {
    value: null,
    filterOption: {},
    list: {
      type: Array,
      required: true,
      default: () => [
        { label: "True", value: true },
        { label: "False", value: false },
      ],
    },
    showBoolNull: {
      type: Boolean,
      default: false,
    },
    showBoolEmpty: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getString() {
      let filter = this.list.filter(
        (b) => this.value !== "" && b.value === this.value
      );
      if (filter.length > 0) return this.$t(filter[0].label);
      return this.value;
    },
  },

  computed: {
    getColor() {
      return this.value
        ? "primary"
        : this.value === null || this.value === ""
        ? ""
        : "negative";
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .q-chip {
  background: transparent;
}
</style>
